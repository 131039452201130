import React from 'react'
import DataTable from 'react-data-table-component';

const renderContactCell = (row) => {
  if (row?.type === 'email') {
    return <span>{row?.contact}</span>;
  } else if (row?.type === 'mobile') {
    return <span>{row?.contact}</span>;
  } else {
    return <span></span>;
  }
};

// const ExpandedComponent = ({ columns }) => {
//   // Ensure `columns` is defined and not null before rendering the table
//   if (!columns || typeof columns !== 'object') {
//     return <div>No data available</div>;
//   }

//   return (
//     <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
//       <thead>
//         <tr>
//           <th style={{ border: '1px solid #ddd', padding: '6px', textAlign: 'left' }}>Field</th>
//           <th style={{ border: '1px solid #ddd', padding: '6px', textAlign: 'left' }}>Value</th>
//         </tr>
//       </thead>
//       <tbody>
//         {Object.keys(columns).map((key) => (
//           <tr key={key}>
//             <td style={{ border: '1px solid #ddd', padding: '6px', textTransform: 'capitalize' }}>{key.replace(/_/g, ' ')}</td>
//             <td style={{ border: '1px solid #ddd', padding: '6px' }}>{columns[key]}</td>
//           </tr>
//         ))}
//       </tbody>
//     </table>
//   );
// };

const DataTableBase = (props) => {
  return (
    <DataTable
      pagination
      direction="auto"
      responsive
      subHeaderAlign="right"
      subHeaderWrap
      striped
      highlightOnHover
      fixedHeader
      // custom={renderContactCell}
      // expandableRows
      // expandableRowsComponent={({ data }) => <ExpandedComponent columns={data} />}
      {...props}
    />
  )
}

export default DataTableBase
