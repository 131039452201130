const appUrl = "https://backend.rcbvip.io";

export const ApiConfig = {
  // =========EndPoints==========
  login: "login",
  newPassword: "newPassword",
  getdata: "pending-kyc-user",
  userTicketList: "users-tickets-list",
  userTicketStatus: "resolve-tickets",
  userRefList: "users-refferal-list",
  cpbalancebycoin: "cpbalancebycoin",
  getverifyData: "approve-kyc-user",
  getrejectedData: "reject-kyc-user",
  getkycData: "kyc-details",
  getUserListData: "users-list",
  verifyIdentity: "update-kyc-status",
  rejectIdentity: "verify-kyc",
  getusers: "getUsers",
  getTotaluser: "total-user-count",
  getVerified: "total-approved-kyc-count",
  getPending: "total-pending-kyc-count",
  todayNewRegistrations: "today_new_registration",
  getSupport: "support",
  getwithdrawal: "withdrawlrequest",
  getregistration: "counnewreg",
  allkyc: "kyc-details",
  coinlist: "coinList",
  currencyPair: "allsinglecoinlist",
  getSubAdminList: "sub-admin-list",
  AddsubAdmin: "add-new-admin",
  AddTrade: "addnewtrader",
  subadmindelete: "delete_subadmin",
  getallorder: "getOrders",
  addNotify: "add_notification",
  deleteNotify: "delete-notification",
  notificationList: "notification-list",
  helplist: "getallhelplist",
  SubadminStatus: "admin_status",
  updateSubadmin: "edit_subadmin",
  tradeStatus: "kycsetstatus",
  getInrWithrawList: "withdraw_request",
  userreqapprove: "confirmInrWithdraw",
  userreqreject: "rejectInrWithdraw",
  addAdTicket: "addAdTicket",
  ticketList: "ticketlist",
  adminsupport: "adminsupport",
  sendmailtouser: "sendmailtouser",
  documentchange: "documentchange",
  transferhistory: "user-trade-history",
  userWallet: "user-wallet",
  admintransfer: "admintransfer",
  depositrequest: "depositrequest",
  withdrawlrequest: "withdrawlrequest",
  totaldepositrequest: "depositrequest",
  totalwithdrawlrequest: "getWithdrawlReq",
  getmasterbal: "getmasterbal",
  changeseen: "changeseen",
  currencyCoinList: "coin-category-list",
  walletCoinList: "adminwallet",
  setcoinwithdrawal: "edit-currency",
  getcpaccountinfo: "getcpaccountinfo",
  getcpcoinbalance: "getcpcoinbalance",
  AddCoinPair: "create-pair",
  AddPairFee: "updatecoinfee",
  createCategory: "create-coin-category",
  getCurrencyPairList: "pair-list",
  tredingReport: "trading-commission",
  sendFundStatus: "transactionstatus",
  completeWithdrawalRequest: "complete_withdrawal_request",
  PendingWithdrwal: "pending_withdrawal_request",
  CancelledWithdrwal: "cancel_withdrawal_request",
  completeDepositRequest: "complete_deposit_request",
  completePendingRequest: "pending_deposit_request",
  inrdepositreq: "deposit_request",
  confirmInrDeposit: "update_deposit_status",
  rejectInrDeposit: "update_deposit_status",
  getUserWalletList: "select_given_coin",
  userWalletTransfer: "withdrawalcoin",
  setrewardrate: "kyc/setrewardrate",
  updatetdsrate: "updatetdsrate",
  updatemailTamplate: "updatemailTamplate",
  traderreport: "user-list",
  pendingtrader: "pendingtrader",
  fiatwithreq: "fiatwithreq",
  verifiedtrader: "verifiedtrader",
  fiatdepreq: "fiatdepreq",
  addNewCoins: "add-currency",
  getMasterWalletList: "adminwallettransactions",
  admincoinaddress: "admincoinaddress",
  getreceive: "getreceive",
  stakingList: "stacking/getAllUserstacking",
  transactionstatus: "update_withdrawal_status",
  Addbanner: "add_banner",
  bannerList: "banner_list",
  updateBanner: "edit_banner",
  bannerdelete: "delete-banner",
  BannerStatus: "set_banner_status",
  deleteCoinPair: "delete-pair",
  walletStatus: "withdrawstatus",
  getOtp: "auth/getcode",
  walletTransfer: "/transfertoother",
  addBankAcc: "add_bank_details",
  getAccDetails: "admin_bank_details",
  fundsTransfer: "debit_credit",
  CoinCategory: "coin-category-list",
  tradingCommission: "trading-commission",
  tradeHistory: 'trade-history',
  OrderBook: 'orderbook',
  updateCoinStatus: 'update-coin-status',
  tradeById: 'find-trades-by-orderid',
  miscellaneousRequest: 'miscellaneous_withdrawal_request',
  MasterAccount: 'master-account',
  botStatus: 'bot-status',
  cancelOrder: 'cancel-order',
  AddNewCoin: 'add-coin',
  getCoinList: 'coins-list',
  deleteCoin: 'delete-coin',
  handleUserStatus: 'update-user-status',
  editCoinStatus: 'edit-coin',
  coinStatus: 'update-coin-status',
  getPackageList: 'get-package-list',
  createNewPackage: 'create-package',
  editPackage: 'edit-package',
  packageStatus: 'update-package-status',
  deletePackage: 'delete-package',
  createNewPhase: 'create-phase',
  getPhaseList: 'get-phase-list',
  PhaseStatus: 'update-phase-status',
  editPhase: 'edit-phase',
  deletePhase: 'delete-phase',
  editprofileData: 'editprofile',
  unVerifieduser: 'reject-kyc-user',
  changeMasterPassword: 'add-master-password',
  changeMasterOtp: 'add-master-otp',



  // ============URLs================
  baseHelp: `${appUrl}/help/`,
  baseTran: `${appUrl}/v1/transaction/`,
  baseUrl: `${appUrl}/api/admin/`,
  baseProfile: `${appUrl}/api/profile/`,
  uploadUrl: `${appUrl}/uploads/`,
  basenotify: `${appUrl}/acc/`,
  baseSecure: `${appUrl}/v1/coin/`,
  baseWallet: `${appUrl}/v1/wallet/`,
  baseCoin: `${appUrl}/v1/coin/`,
  baseReport: `${appUrl}/report/`,
  baseExchange: `${appUrl}/v1/exchange/`,
  uploadcurrency: `${appUrl}/`,
  appUrl: `${appUrl}/`,
  basePackage: `${appUrl}/api/package/`,
  basePhase: `${appUrl}/api/phase/`,
};

