import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../../customComponent/CustomAlertMessage";
import { CSVLink } from "react-csv";
import moment from "moment";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import DataTableBase from "../../../customComponent/DataTable";
import { $ } from "react-jquery-plugin";
import * as xlsx from 'xlsx';
import { saveAs } from 'file-saver';

const ApprovedKyc = () => {

    const [data, setData] = useState([]);
    const [allData, setAllData] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        const indexOfLastData = currentPage * rowsPerPage;
        const indexOfFirstData = indexOfLastData - rowsPerPage;
        setData(allData.slice(indexOfFirstData, indexOfLastData));
    }, [currentPage, rowsPerPage, allData]);

    const calculateSRNo = (index) => {
        return index + 1 + (currentPage - 1) * rowsPerPage;
    };

    const Linkfllow = (row) => {
        return (
            <button className="btn btn-danger btn-sm" href={row?.kyc_id?.document_url} target="_blank" rel="noopener noreferrer" onClick={() => {
                verifyIdentity(row?.userId, 2);
            }} >Recject Kyc</button>
        )
    }



    const columns = [
        { name: "SR No", selector: (row, index) => calculateSRNo(index), grow: 0.2, wrap: true, center: true },
        { name: "Name", selector: row => row.fullName, },
        { name: "Email", wrap: true, selector: row => row.email, },
        { name: "Date of Birth", selector: row => moment(row?.kyc_id?.dob).format("Do MMMM YYYY"), },
        { name: "Date & Time", selector: row => moment(row?.createdAt).format("Do MMMM YYYY"), wrap: true, },
        { name: <div style={{ whiteSpace: 'revert' }}> Document Type </div>, wrap: true, selector: row => row?.kyc_id?.document_type, },
        { name: <div style={{ whiteSpace: 'revert' }}>Document Number</div>, wrap: true, selector: row => row.kyc_id?.document_number, },
        // { name: <div style={{ whiteSpace: 'revert' }}>Reject kyc status</div>, wrap: true, selector: Linkfllow },
    ]
    function searchObjects(e) {
        const keysToSearch = ["fullName", "kyc_id.dob", "email", "kyc_id.document_number", "kyc_id.document_type"];
        const userInput = e.target.value;
        const searchTerm = userInput?.toLowerCase();
        const matchingObjects = allData.filter(obj => {
            return keysToSearch.some(key => {
                const keys = key.split('.'); let value = obj; for (const k of keys) { value = value?.[k]; if (value === undefined) break; }
                return value?.toString()?.toLowerCase()?.includes(searchTerm);
            });
        });

        setData(matchingObjects);
        setCurrentPage(1);
    }

    const downloadExcel = (data) => {
        const formattedData = data?.map((row, index) => ({
            "Sr. No.": calculateSRNo(index),
            "User Name": row?.fullName,
            "Email Id": row?.email,
            "Document Number": row?.kyc_id?.document_number,
            "Date of Birth": moment(row?.kyc_id?.dob).format("Do MMMM YYYY hh:mm A"),
            "Document Type": row?.kyc_id?.document_type,
            "Date & Time": moment(row?.createdAt).format("Do MMMM YYYY hh:mm A"),
        }));
        const worksheet = xlsx.utils.json_to_sheet(formattedData);
        const workbook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(workbook, worksheet, "User Data");
        const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, 'PendingKyc_data.xlsx');
    };


    useEffect(() => {
        handleData()
    }, []);
    const handleData = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.getdataverifylist().then(async result => {
            LoaderHelper.loaderStatus(false);
            if (result.data) {
                try {
                    setData(result?.data?.reverse());
                    setAllData(result?.data?.reverse());
                } catch (error) {
                    alertErrorMessage('No data found');
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage('No data found');
            }
        });
    }

    const verifyIdentity = async (id, status, reason) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.getverifyidentity(id, status, reason).then(
            async (result) => {
                if (result?.success) {
                    LoaderHelper.loaderStatus(false);
                    try {
                        alertSuccessMessage(result?.message);
                        $("#rejectmodal").modal("hide");
                    } catch (error) {
                        alertErrorMessage(error);
                    }
                } else {
                    LoaderHelper.loaderStatus(false);
                    alertErrorMessage(result.message);
                }
            }
        );
    };


    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto mt-4">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon"> <i className="fa fa-user-check" ></i></div>
                                        Approved Kyc
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-xl px-4 mt-n10">
                    <div className="card mb-4">
                        <div className="card-header">Approved Kyc List
                            <div className="col-5">
                                <input className="form-control form-control-solid" id="inputLastName" type="text" placeholder="Search here..." name="search" onChange={searchObjects} />
                            </div>
                            <div className="dropdown">
                                <button className="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fa fa-download me-3"></i>Export</button>
                                <div className="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                                    <button className="dropdown-item" onClick={() => downloadExcel(data)}>
                                        Export to Excel
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive" width="100%">
                                <DataTableBase
                                    columns={columns}
                                    data={data}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={allData?.length}
                                    onChangeRowsPerPage={setRowsPerPage}
                                    onChangePage={setCurrentPage}
                                    paginationPerPage={rowsPerPage}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default ApprovedKyc;