import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { ApiConfig } from "../../../api/apiConfig/ApiConfig";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import moment from "moment";
import { alertErrorMessage } from "../../../customComponent/CustomAlertMessage";
import DataTableBase from "../../../customComponent/DataTable";
import * as xlsx from 'xlsx';
import { saveAs } from 'file-saver';

const HelpandSupport = () => {
    const [data, setData] = useState([]);
    const [allData, setAllData] = useState([]);
    const [userId, setUserId] = useState("");
    const [ticketId, setTicketId] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [showImage, setShowImage] = useState("");
    const [adminReply, setAdminReply] = useState("");

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        switch (name) {
            case "name":
                setName(value);
                break;
            case "email":
                setEmail(value);
                break;
            case "mobileNumber":
                setMobileNumber(value);
                break;
            case "title":
                setTitle(value);
                break;
            case "description":
                setDescription(value);
                break;
            case "adminReply":
                setAdminReply(value);
                break;
            default:
                break;
        }
    };

    const handleData = (row) => {
        setUserId(row.userId);
        setTicketId(row._id);
        setName(row.name);
        setEmail(row.email);
        setMobileNumber(row.mobile_number);
        setTitle(row.title);
        setDescription(row.description);
        setAdminReply(row?.admin_reply);
    };

    const linkFollow = (row) => {
        return (
            <>
                <button className="btn btn-primary btn-sm me-2" data-bs-toggle="modal" data-bs-target="#add_modal"
                    onClick={() => handleData(row)}> <i className="fas fa-eye"></i></button>
            </>
        );
    };

    const imageFormatter = (row) => {
        return (
            <button className="btn btn-sm" onClick={() => handleImageShow(ApiConfig?.appUrl + row?.images[0])} data-bs-toggle="modal" data-bs-target="#ImageModal">
                <i className="fas fa-eye"></i>
            </button>
        );
    };
    const status = (row) => {
        const statusStyle = row?.status === "RESOLVED" ? { color: "green" } : { color: "red" };
        return (
            <span style={statusStyle}>
                {row?.status}
            </span>
        );
    };

    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        const indexOfLastData = currentPage * rowsPerPage;
        const indexOfFirstData = indexOfLastData - rowsPerPage;
        setData(allData.slice(indexOfFirstData, indexOfLastData));
    }, [currentPage, rowsPerPage, allData]);

    const calculateSRNo = (index) => {
        return index + 1 + (currentPage - 1) * rowsPerPage;
    };
    const columns = [
        { name: "SR No", selector: (row, index) => calculateSRNo(index), grow: 0.2, wrap: true, center: true },
        { name: "Name", selector: row => row?.name, grow: .5, wrap: true },
        { name: <div style={{ whiteSpace: 'revert' }}>Mobile Number</div>, selector: row => row?.mobile_number, grow: .5, wrap: true },
        { name: "Email Id", wrap: true, selector: row => row?.email, grow: .5 },
        { name: "Date & Time", selector: row => moment(row?.createdAt).format("Do MMMM YYYY hh:mm A"), wrap: true, grow: .5 },
        { name: <div style={{ whiteSpace: 'revert' }}>Title</div>, wrap: true, selector: row => row?.title, grow: .5 },
        { name: <div style={{ whiteSpace: 'revert' }}>Admin Reply</div>, wrap: true, selector: row => row?.admin_reply, grow: .5 },
        { name: <div style={{ whiteSpace: 'revert' }}>Ticket Number</div>, wrap: true, selector: row => row?.ticket_number, grow: .5 },
        { name: "Snap Shot", selector: imageFormatter, grow: .5 },
        { name: "Status", selector: status, grow: .5, wrap: true },
        { name: "Action", selector: linkFollow },
    ];

    const downloadExcel = (data) => {
        const formattedData = data.map((row, index) => ({
            "Sr. No.": calculateSRNo(index),
            "Name": row?.name,
            "Email Id": row?.email,
            "Mobile Number": row?.mobile_number,
            "Title": row?.title,
            "Ticket Number": row?.ticket_number,
            "Admin Reply": row?.admin_reply,
            "Date & Time": moment(row?.createdAt).format("Do MMMM YYYY hh:mm A"),
        }));

        const worksheet = xlsx.utils.json_to_sheet(formattedData);
        const workbook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(workbook, worksheet, "User Data");

        const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, 'HelpandSupport_data.xlsx');
    };

    const searchObjects = (e) => {
        const keysToSearch = ["name", "email", "mobile_number", "title", "status", "admin_reply", "ticket_number"];
        const userInput = e.target.value;
        const searchTerm = userInput?.toLowerCase();
        const matchingObjects = allData.filter(obj => {
            return keysToSearch.some(key => obj[key]?.toString()?.toLowerCase()?.includes(searchTerm));
        });
        setData(matchingObjects);
    };

    const handleImageShow = (img) => {
        setShowImage(img);
    };

    useEffect(() => {
        const handleGetData = async () => {
            LoaderHelper.loaderStatus(true);
            await AuthService.userTicketList().then(async (result) => {
                if (result.success) {
                    LoaderHelper.loaderStatus(false);
                    try {
                        LoaderHelper.loaderStatus(false);
                        setData(result?.data?.tickets?.reverse());
                        setAllData(result?.data?.tickets?.reverse());
                    } catch (error) {
                        LoaderHelper.loaderStatus(false);
                        alertErrorMessage(error);
                    }
                } else {
                    LoaderHelper.loaderStatus(false);
                }
            });
        };
        handleGetData();
    }, []);

    const handleResolve = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.userTicketStatus(userId, ticketId, adminReply).then(async (result) => {
            if (result?.success) {
                try {
                    LoaderHelper.loaderStatus(false);
                    await AuthService.userTicketList().then((result) => {
                        if (result.success) {
                            allData()
                            LoaderHelper.loaderStatus(false);
                            setData(result?.data?.reverse());
                            setAllData(result?.data.reverse());
                        } else {
                            LoaderHelper.loaderStatus(false);
                            alertErrorMessage(result.message);
                        }
                    });
                } catch (error) {
                    LoaderHelper.loaderStatus(false);
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });
    };


    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto mt-4">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon">
                                            <i className="fas fa-hands-helping"></i>
                                        </div>
                                        Help Center
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="container-xl px-4 mt-n10">
                    <div className="card mb-4">
                        <div className="card-header">
                            Ticket's List
                            <div className="col-5">
                                <input className="form-control form-control-solid" id="inputLastName" type="text" name="search" onChange={searchObjects} />
                            </div>
                            <div className="dropdown">
                                <button className="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                    <i className="fa fa-download me-3"></i>Export
                                </button>
                                <div className="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp" >
                                    <button className="dropdown-item" onClick={() => downloadExcel(data)}>
                                        Export to Excel
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* <div>
                            <h1>Socket.IO Notifications</h1>
                            <div id="notifications">
                                {data.map((data, index) => (
                                    <div key={index}>
                                        {`Ticket No.: ${data.ticket_number}`} <br />
                                        {`Title: ${data.title}`} <br />
                                        {`Description:  ${data.description}`}
                                    </div>
                                ))}
                            </div>
                        </div> */}
                        <div className="card-body">
                            <div className="table-responsive" width="100%">
                                <DataTableBase
                                    columns={columns}
                                    data={data}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={allData.length}
                                    onChangeRowsPerPage={setRowsPerPage}
                                    onChangePage={setCurrentPage}
                                    paginationPerPage={rowsPerPage}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            {/* add_modal */}
            <div className="modal fade" id="add_modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="add_modalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="add_modalLabel">
                                View User Details
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row g-4 gx-md-5">
                                <div className="col-md-6">
                                    <label>
                                        Name <span className="text-danger">* </span>
                                    </label>
                                    <input type="text" onWheel={(e) => e.target.blur()} className="form-control form-control-solid" name="name" value={name} onChange={handleInputChange} disabled />
                                </div>
                                <div className="col-md-6">
                                    <label>
                                        Mobile Number <span className="text-danger">* </span>
                                    </label>
                                    <input type="number" onWheel={(e) => e.target.blur()} className="form-control form-control-solid" name="mobileNumber" value={mobileNumber} onChange={handleInputChange} disabled />
                                </div>
                                <div className="col-md-6">
                                    <label>
                                        Email ID <span className="text-danger">* </span>
                                    </label>
                                    <input type="text" onWheel={(e) => e.target.blur()} className="form-control form-control-solid" name="email" value={email} onChange={handleInputChange} disabled />
                                </div>
                                <div className="col-md-6">
                                    <label>
                                        Title <span className="text-danger">* </span>
                                    </label>
                                    <input type="text" onWheel={(e) => e.target.blur()} className="form-control form-control-solid" name="title" value={title} onChange={handleInputChange} disabled />
                                </div>
                                <div className="col-md-6">
                                    <label>
                                        Admin Reply <span className="text-danger">* </span>
                                    </label>
                                    <textarea type="text" onWheel={(e) => e.target.blur()} className="form-control form-control-solid" name="adminReply" value={adminReply} onChange={handleInputChange} required />
                                </div>
                                <div className="col-md-6">
                                    <label>
                                        Description <span className="text-danger">* </span>
                                    </label>
                                    <textarea type="text" onWheel={(e) => e.target.blur()} className="form-control form-control-solid" name="description" value={description} onChange={handleInputChange} disabled />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger px-5" data-bs-dismiss="modal">
                                Cancel
                            </button>
                            <button type="button" className="btn btn-success px-5" data-bs-dismiss="modal" onClick={handleResolve} >
                                Resolve
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Image Detail */}
            {/* <div className="modal image_modal" id="ImageModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog  alert_modal modal-lg" role="document">
                    <div className="modal-content">
                        <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                        <div className="ratio ratio-16x9">
                            <img src={showImage} className="w-100 cc_modal_img" alt="" />
                        </div>
                    </div>
                </div>
            </div> */}
            {/* Image Detail  */}
            {/* Image Modal start */}
            <div className="modal fade" id="ImageModal" tabIndex="-1" aria-labelledby="ImageModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="ImageModalLabel">Snap Shot</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <img className="img-fluid" src={showImage} alt="Coin_Image" />
                        </div>
                    </div>
                </div>
            </div>
            {/* Image Modal end */}
        </div>
    );
};

export default HelpandSupport;
