import React, { useState } from 'react'
import { alertErrorMessage, alertSuccessMessage } from '../../../customComponent/CustomAlertMessage';
import AuthService from '../../../api/services/AuthService';
import LoaderHelper from '../../../customComponent/Loading/LoaderHelper';

function MasterPassword() {

    const [masterPassword, setMasterPassword] = useState("");
    const [masterOtp, setMasterOtp] = useState("");
    const [checkbox, setCheckbox] = useState("");
    const [checkedbox, setCheckedbox] = useState("");


    const handleInputChange = (event) => {
        switch (event.target.name) {
            case "masterPassword":
                setMasterPassword(event.target.value);
                break;
            case "masterOTP":
                setMasterOtp(event.target.value);
                break;
            default:
        }
    }

    const handleCheckboxChange = async (masterPassword) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.changeMasterPassword(masterPassword).then(async (result) => {
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    alertSuccessMessage(result.message);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
            }
        });
    };
    const handleCheckboxChange2 = async (masterOtp) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.changeMasterOtp(masterOtp).then(async (result) => {
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    alertSuccessMessage(result.message);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
            }
        });
    };



    return (

        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto mt-4">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon">
                                            <i className="fa fa-wave-square"></i>
                                        </div>
                                        Master Password Settings
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-xl px-4 mt-n10">
                    <div className="card mb-4">
                        <div className="card-header">
                            Change Master Password
                        </div>
                        <form>
                            <div className="col-6 mb-3 px-4">
                                <label htmlFor="masterPassword" className="form-label" style={{ fontSize: "20px", fontWeight: 600 }}>Master Password</label>
                                <input type="text" className="form-control" id="masterPassword" placeholder="Master Password" name="masterPassword" value={masterPassword}
                                    onChange={handleInputChange} />

                            </div>
                            <div className="col-12 mb-3 px-4">
                                <input type="checkbox" id="apiCallCheckbox" name="apiCallCheckbox" value={checkedbox} onChange={(e) => setCheckedbox(e.target.checked)} />
                                <label htmlFor="apiCallCheckbox" className="form-label" style={{ fontSize: "16px", fontWeight: 500, marginLeft: "10px" }}>
                                    Click to Change Master Password
                                </label>
                            </div>
                            <div className="col-6 mb-3 px-4">
                                <label htmlFor="masterOTP" className="form-label" style={{ fontSize: "20px", fontWeight: 600 }}>Master OTP</label>
                                <input type="text" className="form-control" id="masterOTP" placeholder="Master OTP" name="masterOTP" value={masterOtp} onChange={handleInputChange}
                                />
                            </div>
                            <div className="col-12 mb-3 px-4">
                                <input type="checkbox" id="apiCallCheckbox" name="apiCallCheckbox" value={checkbox} onChange={(e) => setCheckbox(e.target.checked)} />
                                <label htmlFor="apiCallCheckbox" className="form-label" style={{ fontSize: "16px", fontWeight: 500, marginLeft: "10px" }}>
                                    Click to Change Master OTP
                                </label>
                            </div>
                        </form>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary"
                                onClick={() => {
                                    if (checkbox === true && checkedbox === true) {
                                        handleCheckboxChange(masterPassword);
                                        handleCheckboxChange2(masterOtp);
                                    } else if (checkedbox === true) {
                                        handleCheckboxChange(masterPassword);
                                    } else if (checkbox === true) {
                                        handleCheckboxChange2(masterOtp);
                                    }
                                }}>
                                Save Changes
                            </button>
                        </div>
                    </div>
                </div>
            </main >
        </div >
    )
}

export default MasterPassword