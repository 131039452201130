import Routing from "./Routing";
import React from "react";
import LoaderHelper from "./customComponent/Loading/LoaderHelper";
import Loading from "./customComponent/Loading";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <>
      <Routing />
      <Loading ref={ref => LoaderHelper.setLoader(ref)} />
        <ToastContainer/>
    </>
  );
}

export default App;
