import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../../customComponent/CustomAlertMessage";
import moment from "moment";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import DataTableBase from "../../../customComponent/DataTable";

const PackageManagement = () => {
    const [exportData, setExportData] = useState([]);
    const [allData, setallData] = useState([]);
    const [packageName, setPackageName] = useState('');
    const [packagePrice, setPackagePrice] = useState('');
    const [packageStatus, setpackageStatus] = useState('');
    const [id, setId] = useState('');

    const handleInputChange = (event) => {
        switch (event.target.name) {
            case "packageName":
                setPackageName(event.target.value);
                break;
            case "packagePrice":
                setPackagePrice(event.target.value);
                break;
            case "packageStatus":
                setpackageStatus(event.target.value);
                break;

            default:
        }
    }

    const AddNewPackage = async (packageName, packagePrice) => {
        try {
            const result = await AuthService.createNewPackage(packageName, packagePrice);
            if (result.success) {
                alertSuccessMessage(result.message);
                handleData();
            } else {
                alertErrorMessage(result.message);
            }
        } catch (error) {
            alertErrorMessage(error);
        } finally {
            LoaderHelper.loaderStatus(false);
        }
    };

    useEffect(() => {
        handleData();
    }, []);

    const handleData = async () => {
        LoaderHelper.loaderStatus(true);
        try {
            const result = await AuthService.getPackageList();
            if (result.success) {
                setExportData(result?.data?.reverse());
                setallData(result?.data.reverse());
                if (result?.data?.length > 0) {
                    setId(result?.data[0]._id);
                }
            } else {
                alertErrorMessage(result?.message);
            }
        } catch (error) {
        } finally {
            LoaderHelper.loaderStatus(false);
        }
    };


    const linkFollow = (row) => (
        <div>
            <button className="btn btn-success btn-sm me-2" data-bs-toggle="modal" data-bs-target="#view_modal">
                <i class="fas fa-eye"></i>
            </button>
            <button className="btn btn-primary btn-sm me-2" data-bs-toggle="modal" data-bs-target="#edit_modal" onClick={() => handleEditModalOpen(row)}>
                <i class="fas fa-edit"></i>
            </button>
            <button className="btn btn-danger btn-sm" type="button" onClick={() => handleDelete(row?._id)}><i class="fas fa-trash-alt"></i></button>
        </div>
    );

    const linkFollow1 = (row) => (
        <div>
            {row?.package_status === "ACTIVE" ? (
                <button className="btn btn-success btn-sm me-2" onClick={() => handleStatus(row?._id, "INACTIVE")}>
                    Active
                </button>
            ) : (
                <button className="btn btn-danger btn-sm me-2" onClick={() => handleStatus(row?._id, "ACTIVE")}>
                    Inactive
                </button>
            )}
        </div>
    );
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        const indexOfLastData = currentPage * rowsPerPage;
        const indexOfFirstData = indexOfLastData - rowsPerPage;
        setExportData(allData.slice(indexOfFirstData, indexOfLastData));
    }, [currentPage, rowsPerPage, allData]);

    const calculateSRNo = (index) => {
        return index + 1 + (currentPage - 1) * rowsPerPage;
    };
    const columns = [
        { name: "SR No", selector: (row, index) => calculateSRNo(index), grow: 0.2, wrap: true, center: true },
        { name: "Package Name", selector: (row) => row?.package_name, sort: true, wrap: true, },
        { name: "Package Price ", selector: (row) => row?.package_price, sort: true, wrap: true, },
        { name: "Date", selector: (row) => moment(row?.createdAt).format("Do MMMM YYYY"), sort: true, wrap: true, },
        { name: "Package Status", selector: linkFollow1 },
        { name: "Action", cell: linkFollow, wrap: true },
    ];

    function searchObjects(e) {
        const keysToSearch = ["package_name", "package_price"];
        const userInput = e.target.value;
        const searchTerm = userInput?.toLowerCase();
        const matchingObjects = allData?.filter((obj) =>
            keysToSearch.some((key) => obj[key]?.toString()?.toLowerCase()?.includes(searchTerm))
        );
        setExportData(matchingObjects);
    }


    const handleStatus = async (id, status) => {
        LoaderHelper.loaderStatus(true);
        try {
            const result = await AuthService.packageStatus(id, status);
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                handleData();
                alertSuccessMessage(result?.message);
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result?.message);
            }
        } catch (error) {
            LoaderHelper.loaderStatus(false);
            alertErrorMessage(error);
        } finally {
            LoaderHelper.loaderStatus(false);
        }
    };
    const handleEditPackage = async (packageName, packagePrice, id) => {
        LoaderHelper.loaderStatus(true);
        try {
            const result = await AuthService.editPackage(packageName, packagePrice, id);
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                alertSuccessMessage(result?.message);
                handleData();
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        } catch (error) {
            alertErrorMessage(error);
        } finally {
            LoaderHelper.loaderStatus(false);
        }
    };

    const handleEditModalOpen = (row) => {
        setId(row?._id);
        setPackageName(row?.package_name);
        setPackagePrice(row?.package_price);

    };


    const handleDelete = async (id) => {
        LoaderHelper.loaderStatus(true);
        try {
            const result = await AuthService.deletePackage(id);
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                alertSuccessMessage(result?.message);
                handleData();
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result?.message);
            }
        } catch (error) {
            alertErrorMessage(error);
        } finally {
            LoaderHelper.loaderStatus(false);
        }
    };


    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto mt-4">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon">
                                            <i className="fa fa-wave-square"></i>
                                        </div>
                                        Package Management List
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-xl px-4 mt-n10">
                    <div className="card mb-4">
                        <div className="card-header d-flex justify-content-between">
                            Package Details
                            <div className="col-5">
                                <input className="form-control form-control-solid" id="inputLastName" type="text" placeholder="Search here..." name="search" onChange={searchObjects} />
                            </div>
                            <div className="dropdown">
                                <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#add_modal">
                                    <i className="fas fa-plus me-2"></i> Create Package
                                </button>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive" width="100%">
                                <DataTableBase
                                    columns={columns}
                                    data={exportData}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={allData.length}
                                    onChangeRowsPerPage={setRowsPerPage}
                                    onChangePage={setCurrentPage}
                                    paginationPerPage={rowsPerPage}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            {/* Add Modal */}
            <div className="modal fade" id="add_modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1```jsx aria-labelledby=" aria-hidden="true" >
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="add_modalLabel">
                                Add New Package
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row g-4 gx-md-5">
                                <div className="col-md-6">
                                    <label>
                                        Package Name <span className="text-danger">* </span>
                                    </label>
                                    <input type="text" onWheel={(e) => e.target.blur()} className="form-control form-control-solid" name="packageName" placeholder="Enter packege Name" value={packageName}
                                        onChange={handleInputChange} required />
                                </div>
                                <div className="col-md-6">
                                    <label>
                                        Package Price<span className="text-danger">*</span>
                                    </label>
                                    <input type="text" onWheel={(e) => e.target.blur()} className="form-control form-control-solid" name="packagePrice" placeholder="Enter packege price" value={packagePrice}
                                        onChange={handleInputChange} />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger px-5" data-bs-dismiss="modal">
                                Cancel
                            </button>
                            <button type="button" className="btn btn-success px-5" data-bs-dismiss="modal"
                                onClick={() => AddNewPackage(packageName, packagePrice, packageStatus)}>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Edit Modal */}
            <div className="modal fade" id="edit_modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="edit_modalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="edit_modalLabel">
                                Edit Package Details
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row g-4 gx-md-5">
                                <div className="col-md-6">
                                    <label>
                                        Package Name <span className="text-danger">* </span>
                                    </label>
                                    <input type="text" onWheel={(e) => e.target.blur()} className="form-control form-control-solid" name="packageName" placeholder="Enter Package Name" value={packageName}
                                        onChange={handleInputChange} required />
                                </div>
                                <div className="col-md-6">
                                    <label>
                                        Package Price <span className="text-danger">* </span>
                                    </label>
                                    <input type="text" onWheel={(e) => e.target.blur()} className="form-control form-control-solid" name="packagePrice" placeholder="Enter Package Price" value={packagePrice}
                                        onChange={handleInputChange} required />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-dark px-5" data-bs-dismiss="modal">
                                Cancel
                            </button>
                            <button type="button" className="btn btn-primary px-5" data-bs-dismiss="modal"
                                onClick={() => handleEditPackage(packageName, packagePrice, id)}>

                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* View Modal */}
            <div className="modal fade" id="view_modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="edit_modalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="edit_modalLabel">
                                View Package Details
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row g-4 gx-md-5">
                                <div className="col-md-6">
                                    <label>
                                        Package Name <span className="text-danger">* </span>
                                    </label>
                                    <input type="text" onWheel={(e) => e.target.blur()} className="form-control form-control-solid" name="packageName" placeholder="Enter Package Name" value={packageName}
                                        onChange={handleInputChange} required />
                                </div>
                                <div className="col-md-6">
                                    <label>
                                        Package Price <span className="text-danger">* </span>
                                    </label>
                                    <input type="text" onWheel={(e) => e.target.blur()} className="form-control form-control-solid" name="packagePrice" placeholder="Enter Package Price" value={packagePrice}
                                        onChange={handleInputChange} required />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-dark px-5" data-bs-dismiss="modal">
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default PackageManagement;
