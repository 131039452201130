// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.verifybtn{
    border: 1px solid #283777;
    background: #283777;
    color: #fff;
    padding: 3px 5px;
    border-radius: 4px;
    margin: 20px;
}


.btn-indigo {
    color: #fff;
    background-color: #5800e8;
    border-color: #5800e8;
}

.btn-white-10 {
    color: rgba(255, 255, 255, 0.5);
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.5) !important;
}

.btnCSV{
   
    width: auto;
    color: #fff;
   
    float: right;
    border: 0;
  
}


.btnCSV:hover{
   border: 0;
    color: rgb(199, 199, 224);
    background-color: #5a08dd;
    border-color: #123fa1;
}`, "",{"version":3,"sources":["webpack://./src/ui/pages/PendingKyc/style.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,WAAW;IACX,gBAAgB;IAChB,kBAAkB;IAClB,YAAY;AAChB;;;AAGA;IACI,WAAW;IACX,yBAAyB;IACzB,qBAAqB;AACzB;;AAEA;IACI,+BAA+B;IAC/B,0CAA0C;IAC1C,yBAAyB;IACzB,0CAA0C;AAC9C;;AAEA;;IAEI,WAAW;IACX,WAAW;;IAEX,YAAY;IACZ,SAAS;;AAEb;;;AAGA;GACG,SAAS;IACR,yBAAyB;IACzB,yBAAyB;IACzB,qBAAqB;AACzB","sourcesContent":[".verifybtn{\n    border: 1px solid #283777;\n    background: #283777;\n    color: #fff;\n    padding: 3px 5px;\n    border-radius: 4px;\n    margin: 20px;\n}\n\n\n.btn-indigo {\n    color: #fff;\n    background-color: #5800e8;\n    border-color: #5800e8;\n}\n\n.btn-white-10 {\n    color: rgba(255, 255, 255, 0.5);\n    background-color: rgba(255, 255, 255, 0.1);\n    border-color: transparent;\n    color: rgba(255, 255, 255, 0.5) !important;\n}\n\n.btnCSV{\n   \n    width: auto;\n    color: #fff;\n   \n    float: right;\n    border: 0;\n  \n}\n\n\n.btnCSV:hover{\n   border: 0;\n    color: rgb(199, 199, 224);\n    background-color: #5a08dd;\n    border-color: #123fa1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
