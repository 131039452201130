import React, { useEffect, useState } from 'react'
import { alertErrorMessage, alertSuccessMessage } from '../../../customComponent/CustomAlertMessage';
import AuthService from '../../../api/services/AuthService';
import { CSVLink } from 'react-csv';
import DataTableBase from '../../../customComponent/DataTable';
import moment from 'moment';
import { ApiConfig } from '../../../api/apiConfig/ApiConfig';
import LoaderHelper from '../../../customComponent/Loading/LoaderHelper';
import * as xlsx from 'xlsx';
import { saveAs } from 'file-saver';
const ReferralList = () => {

    const [data, setData] = useState([]);
    const [allData, setAllData] = useState([]);


    useEffect(() => {
        const handleData = async () => {
            LoaderHelper.loaderStatus(true);
            try {
                const result = await AuthService.userRefList();
                if (result.success) {
                    LoaderHelper.loaderStatus(false);
                    setData(result?.data);
                    setAllData(result?.data?.reverse());
                    alertSuccessMessage(result.message);
                } else {
                    LoaderHelper.loaderStatus(false);
                    alertErrorMessage(result.message);
                }
            } catch (error) {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(error);
            }
        };
        handleData()
    }, [])


    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        const indexOfLastData = currentPage * rowsPerPage;
        const indexOfFirstData = indexOfLastData - rowsPerPage;
        setData(allData.slice(indexOfFirstData, indexOfLastData));
    }, [currentPage, rowsPerPage, allData]);

    const calculateSRNo = (index) => {
        return index + 1 + (currentPage - 1) * rowsPerPage;
    };
    const columns = [
        { name: "SR No", selector: (row, index) => calculateSRNo(index), grow: 0.2, wrap: true, center: true },
        { name: "User Name", wrap: true, selector: row => row?.user_name, grow: .5 },
        { name: "User Code", wrap: true, selector: row => row?.user_code, grow: .5 },
        { name: "Sponser Name", selector: row => row?.sponser_name, grow: .5, wrap: true, },
        { name: <div style={{ whiteSpace: 'revert' }}>Sponser Code</div>, selector: row => row?.sponser_code, grow: .5, wrap: true },
        { name: "Total Referred", selector: row => row?.total_referred, grow: .5, wrap: true, },
        { name: "Total Points", selector: row => row?.coin_balance, grow: .5, wrap: true },
        { name: "Date", selector: row => moment(row?.createdAt).format("Do MMMM YYYY"), wrap: true, grow: .5 },

    ];

    const downloadExcel = (data) => {
        const formattedData = data.map((row, index) => ({
            "Sr. No.": calculateSRNo(index),
            "User Name": row?.user_name,
            "User Code": row?.user_code,
            "Sponser Code": row?.sponser_code,
            "Sponser Name": row?.sponser_name,
            "Total Referred": row?.total_referred,
            "Total Points": row?.coin_balance,
            "Date & Time": moment(row?.createdAt).format("Do MMMM YYYY hh:mm A"),
        }));

        const worksheet = xlsx?.utils.json_to_sheet(formattedData);
        const workbook = xlsx?.utils.book_new();
        xlsx?.utils.book_append_sheet(workbook, worksheet, "User Data");

        const excelBuffer = xlsx?.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, 'ReferralList_data.xlsx');
    };

    const searchObjects = (e) => {
        const keysToSearch = ["user_name", "user_code", "sponser_code", "sponser_name"];
        const userInput = e.target.value;
        const searchTerm = userInput?.toLowerCase();
        const matchingObjects = allData.filter(obj => {
            return keysToSearch.some(key => obj[key]?.toString()?.toLowerCase()?.includes(searchTerm));
        });
        setData(matchingObjects);
    };

    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto mt-4">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon">
                                            <i className="fa fa-check-circle"></i>
                                        </div>
                                        User's Details
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-xl px-4 mt-n10">
                    <div className="card mb-4">
                        <div className="card-header d-flex justify-content-between">
                            User Verification Details
                            <div className="col-5">
                                <input className="form-control form-control-solid" id="inputLastName" type="text" placeholder="Search here..." name="search" onChange={searchObjects} />
                            </div>
                            <div className="dropdown">
                                <button className="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" ype="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fa fa-download me-3"></i> Export
                                </button>
                                <div
                                    className="dropdown-menu animated--fade-in-up"
                                    aria-labelledby="dropdownFadeInUp"
                                >
                                    <button className="dropdown-item" onClick={() => downloadExcel(allData)} > Export to Excel</button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive" width="100%">
                                <DataTableBase
                                    columns={columns}
                                    data={data}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={allData.length}
                                    onChangeRowsPerPage={setRowsPerPage}
                                    onChangePage={setCurrentPage}
                                    paginationPerPage={rowsPerPage}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>)
}

export default ReferralList