import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import "./style.css";
import VerifyKyc from "../VerifyKyc";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import moment from "moment";
import { alertErrorMessage, } from "../../../customComponent/CustomAlertMessage";
import DataTableBase from "../../../customComponent/DataTable";
import * as xlsx from 'xlsx';
import { saveAs } from 'file-saver';

const PendingKyc = () => {
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [activeScreen, setActiveScreen] = useState("pending");
  const [userId, setUserId] = useState("");
  const [userKycData, setuserKycData] = useState();

  const linkFollow = (row) => {
    return (
      <button className="verifybtn btn btn-primary" onClick={() => { setUserId(row?._id); setuserKycData(row?.kycdata, "clicked"); setActiveScreen("detail") }}>
        <i class="fas fa-eye"></i>
      </button>
    );
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const indexOfLastData = currentPage * rowsPerPage;
    const indexOfFirstData = indexOfLastData - rowsPerPage;
    setData(allData.slice(indexOfFirstData, indexOfLastData));
  }, [currentPage, rowsPerPage, allData]);

  const calculateSRNo = (index) => {
    return index + 1 + (currentPage - 1) * rowsPerPage;
  };
  const columns = [
    { name: "SR No", selector: (row, index) => calculateSRNo(index), grow: 0.2, wrap: true, center: true },
    { name: "Name", selector: row => row?.fullName, wrap: true, },
    { name: "Email Id", wrap: true, selector: row => row.email, },
    { name: "Date", selector: row => moment(row?.createdAt).format("Do MMMM YYYY"), wrap: true, },
    { name: <div style={{ whiteSpace: 'revert' }}>Document Number</div>, wrap: true, selector: row => row?.kyc_id?.document_number, },
    { name: "DOB", selector: row => moment(row?.kyc_id?.dob).format("Do MMMM YYYY"), wrap: true, },
    { name: "Action", selector: linkFollow, },
  ];
  function searchObjects(e) {
    const keysToSearch = ["fullName", "email", "kyc_id.document_number"];
    const userInput = e.target.value;
    const searchTerm = userInput?.toLowerCase();

    const matchingObjects = allData.filter(obj => {
      return keysToSearch.some(key => {
        const keys = key.split('.');
        let value = obj;
        for (const k of keys) {
          value = value?.[k];
          if (value === undefined) break;
        }
        return value?.toString()?.toLowerCase()?.includes(searchTerm);
      });
    });

    setData(matchingObjects);
    setCurrentPage(1);
  }

  useEffect(() => {
    handleData();
  }, []);

  const downloadExcel = (data) => {
    const formattedData = data?.map((row, index) => ({
      "Sr. No.": calculateSRNo(index),
      "User Name": row?.fullName,
      "Email Id": row?.email,
      "Document Number": row?.kyc_id?.document_number,
      "Date of Birth": moment(row?.createdAt).format("Do MMMM YYYY hh:mm A"),
    }));
    const worksheet = xlsx?.utils.json_to_sheet(formattedData);
    console.log(formattedData, "formattedData");
    const workbook = xlsx?.utils.book_new();
    xlsx?.utils.book_append_sheet(workbook, worksheet, "User Data");
    const excelBuffer = xlsx?.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, 'PendingKyc_data.xlsx');
  };


  const handleData = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getdata().then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setData(result?.data?.reverse());
          setAllData(result?.data?.reverse());
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
      }
    });
  };



  return activeScreen === "pending" ? (
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
          <div className="container-xl px-4">
            <div className="page-header-content pt-4">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto mt-4">
                  <h1 className="page-header-title">
                    <div className="page-header-icon">
                      <i className=" fa fa-user-slash"></i>
                    </div>
                    Pending Kyc
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="container-xl px-4 mt-n10">
          <div className="card mb-4">
            <div className="card-header">
              Pending Kyc List
              <div className="col-5">
                <input className="form-control form-control-solid" id="inputLastName" type="text" placeholder="Search here..." name="search" onChange={searchObjects} />
              </div>
              <div className="dropdown">
                <button className="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                  <i className="fa fa-download me-3"></i>Export
                </button>
                <div className="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp" >
                  <button className="dropdown-item" onClick={() => downloadExcel(data)}>
                    Export as Excel
                  </button>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive" width="100%">
                <DataTableBase
                  columns={columns}
                  data={data}
                  pagination
                  paginationServer
                  paginationTotalRows={allData?.length}
                  onChangeRowsPerPage={setRowsPerPage}
                  onChangePage={setCurrentPage}
                  paginationPerPage={rowsPerPage}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  ) : (
    <VerifyKyc userId={userId} kycData={userKycData} />
  );
};
export default PendingKyc;
