import React, { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import Header from "../../../customComponent/Header";
import { useEffect } from "react";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage } from "../../../customComponent/CustomAlertMessage";

const DashboardPage = () => {

    const [allData, setAllData] = useState([]);

    const [activeTab, setActiveTab] = useState('');
    const myPermission = sessionStorage.getItem('permissions');
    const userType = sessionStorage.getItem('userType');

    let permissions = Array.isArray(JSON.parse(myPermission)) ? JSON.parse(myPermission)?.map(x => x.value) : [];

    useEffect(() => {
        let URL = window.location.href?.split('/');
        let route = URL.pop();
        setActiveTab(route)
    }, []);

    useEffect(() => {
        handleGetData();
    }, []);
    const handleGetData = async () => {
        LoaderHelper.loaderStatus(true);
        try {
            const result = await AuthService?.userTicketList();
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                const tickets = result?.data?.tickets || [];
                setAllData(tickets);
            } else {
                alertErrorMessage('Failed to fetch tickets.');
            }
        } catch (error) {
            alertErrorMessage(error);
        } finally {
            LoaderHelper.loaderStatus(false);
        }
    };


    const pendingTicketsCount = Array?.isArray(allData)
        ? allData?.filter(ticket => ticket.status === 'PENDING').length
        : 0;

    return (
        <>
            <Header />
            <div id="layoutSidenav" >
                <div id="layoutSidenav_nav">
                    <nav className="sidenav shadow-right sidenav-light">
                        <div className="sidenav-menu">
                            <div className="nav accordion" id="accordionSidenav">
                                <div className="sidenav-menu-heading">pages</div>

                                <Link to="/dashboard/homepage" className={`nav-link collapsed ${activeTab?.includes('homepage') ? 'active' : ''}`} onClick={() => setActiveTab('homepage')}>
                                    <div className="nav-link-icon"><i className="fa fa-th"></i></div>
                                    Dashboards
                                </Link>
                                {permissions.includes(1) || userType === '1' ?
                                    <Link className={`nav-link collapsed ${activeTab?.includes('userList') ? 'active' : ''}`} to="userList" onClick={() => setActiveTab('userList')}>
                                        <div className="nav-link-icon"><i class="fas fa-users"></i></div>
                                        User List
                                    </Link>
                                    : null
                                }
                                {permissions.includes(2) || userType === '1' ?
                                    <>
                                        <div className={`nav-link collapsed ${activeTab?.includes('coinList') ? 'active' : ''}`} data-bs-toggle="collapse" data-bs-target="#collapseCoinManagement" aria-expanded="false" aria-controls="collapseCoinManagement">
                                            <div className="nav-link-icon"><i className="fa fa-wave-square"></i></div>
                                            Coin Management
                                            <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                        </div>

                                        <div className="collapse" id="collapseCoinManagement" data-bs-parent="#accordionSidenav">
                                            <nav className="sidenav-menu-nested nav">
                                                <Link className={`nav-link  ${activeTab?.includes('coinList') ? 'active' : ''}`} to="coinList" onClick={() => setActiveTab('coinList')}>
                                                    Coin List</Link>
                                                {/* <Link className="nav-link" to="" onClick={() => setActiveTab('addnewtrade')}>Add New Trader </Link> */}
                                            </nav>
                                        </div>
                                    </>
                                    : null
                                }

                                {permissions.includes(3) || userType === '1' ?
                                    <>
                                        <div className={`nav-link collapsed ${(activeTab?.includes('pendingkyc') || activeTab?.includes('approvedkyc') || activeTab?.includes('RejectedKyc')) ? 'active' : ''}`} data-bs-toggle="collapse" data-bs-target="#collapseKyc" aria-expanded="false" aria-controls="collapseKyc">
                                            <div className="nav-link-icon"><i className="fa fa-check-circle"></i></div>
                                            KYC Manager
                                            <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                        </div>

                                        <div className="collapse" id="collapseKyc" data-bs-parent="#accordionSidenav">
                                            <nav className="sidenav-menu-nested nav">

                                                <Link className={`nav-link ${activeTab?.includes('pendingkyc') ? 'active' : ''}`} to="pendingkyc" onClick={() => setActiveTab('pendingkyc')}>Pending KYC</Link>

                                                <Link className={`nav-link  ${activeTab?.includes('approvedkyc') ? 'active' : ''}`} to="approvedkyc" onClick={() => setActiveTab('approvedkyc')}>Approved KYC</Link>

                                                <Link className={`nav-link  ${activeTab?.includes('RejectedKyc') ? 'active' : ''}`} to="RejectedKyc" onClick={() => setActiveTab('RejectedKyc')}>Rejected KYC</Link>
                                            </nav>
                                        </div>
                                    </>
                                    : null
                                }
                                {permissions.includes(1) || userType === '1' ?
                                    <Link className={`nav-link collapsed ${activeTab?.includes('packageManagement') ? 'active' : ''}`} to="packageManagement" onClick={() => setActiveTab('packageManagement')}>
                                        <div className="nav-link-icon"><i class="fas fa-people-carry"></i></div>
                                        Package Management
                                    </Link>
                                    : null
                                }
                                {permissions.includes(1) || userType === '1' ?
                                    <Link className={`nav-link collapsed ${activeTab?.includes('phaseManagement') ? 'active' : ''}`} to="phaseManagement" onClick={() => setActiveTab('phaseManagement')}>
                                        <div className="nav-link-icon"><i class="fab fa-usps"></i></div>
                                        Phase Management
                                    </Link>
                                    : null
                                }

                                {permissions.includes(1) || userType === '1' ?
                                    <Link className={`nav-link collapsed ${activeTab?.includes('helpandsupport') ? 'active' : ''}`} to="helpandsupport" onClick={() => setActiveTab('helpandsupport')}>
                                        <div className="nav-link-icon"><i class="fab fa-hire-a-helper"></i></div>
                                        Help Center &nbsp;
                                        <span className="bg-danger" style={{ color: 'white', border: '1px solid red', padding: '5px', borderRadius: '5px', boxShadow: '1px 1px 1px 1px black' }} >{pendingTicketsCount > 0 ? pendingTicketsCount : null}</span>
                                    </Link>
                                    : null
                                }

                                {permissions.includes(1) || userType === '1' ?
                                    <Link className={`nav-link collapsed ${activeTab?.includes('ReferralList') ? 'active' : ''}`} to="ReferralList" onClick={() => setActiveTab('ReferralList')}>
                                        <div className="nav-link-icon"><i class="fas fa-asterisk"></i></div>
                                        Referral List
                                    </Link>
                                    : null
                                }

                                {userType === '1' ?
                                    <>
                                        <div className={`nav-link collapsed ${activeTab?.includes('Settings') || activeTab?.includes('MasterPassword') ? 'active' : ''}`} data-bs-toggle="collapse" data-bs-target="#collapseSubAdmin" aria-expanded="false" aria-controls="collapseSubAdmin">
                                            <div className="nav-link-icon"><i className="fa fa-user-friends"></i></div>
                                            Settings
                                            <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                        </div>
                                        <div className="collapse" id="collapseSubAdmin" data-bs-parent="#accordionSidenav">
                                            <nav className="sidenav-menu-nested nav">
                                                <Link className={`nav-link  ${activeTab?.includes('MasterPassword') ? 'active' : ''}`} to="MasterPassword" onClick={() => setActiveTab('MasterPassword')}>MasterPassword</Link>

                                                {/* <Link className={`nav-link  ${activeTab?.includes('addsubadmin') ? 'active' : ''}`} to="addsubadmin" onClick={() => setActiveTab('addsubadmin')}>Add New</Link> */}
                                            </nav>
                                        </div>
                                    </>
                                    : null
                                }
                                {/* {permissions.includes(1) || userType === '1' ?
                                    <>
                                        <div className={`nav-link collapsed ${activeTab?.includes('PackageManagement') ? 'active' : ''}`} data-bs-toggle="collapse" data-bs-target="#collapsePackageManagement" aria-expanded="false" aria-controls="collapsePackageManagement">
                                            <div className="nav-link-icon"><i className="fa fa-wave-square"></i></div>
                                            Package Management
                                            <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                        </div>

                                        <div className="collapse" id="collapsePackageManagement" data-bs-parent="#accordionSidenav">
                                            <nav className="sidenav-menu-nested nav">
                                                <Link className={`nav-link  ${activeTab?.includes('PackageList') ? 'active' : ''}`} to="PackageList" onClick={() => setActiveTab('PackageList')}>Package List</Link>
                                                <Link className="nav-link" to="" onClick={() => setActiveTab('addnewtrade')}>Add New Trader </Link>
                                            </nav>
                                        </div>
                                    </>
                                    : null
                                } */}

                                {/* {permissions.includes(3) || userType === '1' ?
                                    <Link className={`nav-link collapsed ${activeTab?.includes('currencymanagement') ? 'active' : ''}`} to="currencymanagement" onClick={() => setActiveTab('currencymanagement')}>
                                        <div className="nav-link-icon"><i className="fa fa-dollar-sign"></i></div>
                                        Currency Management
                                    </Link>
                                    : null
                                }
                                {permissions.includes(5) || userType === '1' ?
                                    <Link className={`nav-link collapsed ${activeTab?.includes('currencypair') ? 'active' : ''}`} onClick={() => setActiveTab('currencypair')} to="currencypair">
                                        <div className="nav-link-icon"><i className="fa fa-prescription"></i></div>
                                        Currency Pair Management
                                    </Link>
                                    : null
                                }
                                {permissions.includes(7) || userType === '1' ?
                                    <>
                                        <div className={`nav-link collapsed ${(activeTab?.includes('fundsDManagement') || activeTab?.includes('FundsPendingDeposit')) ? 'active' : ''}`} data-bs-toggle="collapse" data-bs-target="#collapseFundsManagement" aria-expanded="false" aria-controls="collapseSubAdmin">
                                            <div className="nav-link-icon"><i className="fa fa-dollar-sign"></i></div>
                                            Funds Deposit Management
                                            <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                        </div>
                                        <div className="collapse" id="collapseFundsManagement" data-bs-parent="#accordionSidenav">
                                            <nav className="sidenav-menu-nested nav">
                                                <Link className={`nav-link  ${activeTab?.includes('fundsDManagement') ? 'active' : ''}`} to="fundsDManagement" onClick={() => setActiveTab('fundsDManagement')}>Completed Deposit</Link>
                                                <Link className={`nav-link  ${activeTab?.includes('FundsPendingDeposit') ? 'active' : ''}`} to="FundsPendingDeposit" onClick={() => setActiveTab('FundsPendingDeposit')}>Pending Deposit</Link>
                                            </nav>
                                        </div>
                                    </>
                                    : null
                                }

                                {permissions.includes(7) || userType === '1' ?
                                    <>
                                        <div className={`nav-link collapsed ${(activeTab?.includes('fundsManagement') || activeTab?.includes('FundsPendingWithdrawal') || activeTab?.includes('FundsCancelledWithdrawal')) ? 'active' : ''}`} data-bs-toggle="collapse" data-bs-target="#collapsefundsWithdrawal" aria-expanded="false" aria-controls="collapsefundsWithdrawal">
                                            <div className="nav-link-icon"><i className="fab fa-google-wallet"></i></div>
                                            Funds Withdrawal Management
                                            <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                        </div>
                                        <div className="collapse" id="collapsefundsWithdrawal" data-bs-parent="#accordionSidenav">
                                            <nav className="sidenav-menu-nested nav">
                                                <Link className={`nav-link  ${activeTab?.includes('fundsManagement') ? 'active' : ''}`} to="fundsManagement" onClick={() => setActiveTab('fundsManagement')}>Completed Withdrawal</Link>

                                                <Link className={`nav-link  ${activeTab?.includes('FundsPendingWithdrawal') ? 'active' : ''}`} to="FundsPendingWithdrawal" onClick={() => setActiveTab('FundsPendingWithdrawal')}>Pending Withdrawal</Link>

                                                <Link className={`nav-link  ${activeTab?.includes('FundsCancelledWithdrawal') ? 'active' : ''}`} to="FundsCancelledWithdrawal" onClick={() => setActiveTab('FundsCancelledWithdrawal')}>Cancelled Withdrawal</Link>

                                            </nav>
                                        </div>
                                    </>
                                    : null
                                } */}
                                {/* {permissions.includes(6) || userType === '1' ?
                                    <>
                                        <div className={`nav-link collapsed ${(activeTab?.includes('TradingCommision') || activeTab?.includes('WithdrawalFees') || activeTab?.includes('MiscellaneousPage')) ? 'active' : ''}`} data-bs-toggle="collapse" data-bs-target="#collapseTradingCommision" aria-expanded="false" aria-controls="collapseTradingCommision">
                                            <div className="nav-link-icon"><i className="fab fa-google-wallet"></i></div>
                                            Exchange Profit
                                            <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                        </div>
                                        <div className="collapse" id="collapseTradingCommision" data-bs-parent="#accordionSidenav">
                                            <nav className="sidenav-menu-nested nav">

                                                <Link className={`nav-link  ${activeTab?.includes('TradingCommision') ? 'active' : ''}`} to="TradingCommision" onClick={() => setActiveTab('TradingCommision')}>   Trading Commision</Link>


                                                <Link className={`nav-link  ${activeTab?.includes('MiscellaneousPage') ? 'active' : ''}`} to="MiscellaneousPage" onClick={() => setActiveTab('MiscellaneousPage')}>Miscellaneous Withdrawal </Link>

                                            </nav>
                                        </div>
                                    </>
                                    : null
                                } */}
                                {/* {permissions.includes(4) || userType === '1' ? (
                                    <Link className={`nav-link collapsed ${activeTab?.includes('tradersbalance') ? 'active' : ''}`} to="tradersbalance" onClick={() => setActiveTab("tradersbalance")}>
                                        <div className="nav-link-icon"><i className="fa fa-wallet"></i></div>
                                        Exchange Wallet Management
                                    </Link>
                                ) : null} */}
                                {/* {permissions.includes(8) || userType === '1' ?
                                    <Link className={`nav-link collapsed ${activeTab?.includes('tradingfeereport') ? 'active' : ''}`} to="tradingfeereport" onClick={() => setActiveTab('tradingfeereport')}>
                                        <div className="nav-link-icon"><i className="fa fa-wave-square"></i></div>
                                        Market Trades
                                    </Link>
                                    : null
                                }
                                {permissions.includes(9) || userType === '1' ?
                                    <Link className={`nav-link collapsed ${activeTab?.includes('OrderBook') ? 'active' : ''}`} to="OrderBook" onClick={() => setActiveTab('OrderBook')}>
                                        <div className="nav-link-icon"><i className="fa fa-list"></i></div>
                                        OrderBook
                                    </Link>
                                    : null
                                } */}
                                {/* {permissions.includes(10) || userType === '1' ?

                                    <Link className={`nav-link collapsed ${activeTab?.includes('notification') ? 'active' : ''}`} to="notification" onClick={() => setActiveTab('notification')}>
                                        <div className="nav-link-icon"><i className="fa fa-bell"></i></div>
                                        Notifications Management
                                    </Link>
                                    : null
                                }
                                {userType === '1' ?
                                    <Link className={`nav-link collapsed ${activeTab?.includes('bannerManagement') ? 'active' : ''}`} to="bannerManagement" onClick={() => setActiveTab('bannerManagement')}>
                                        <div className="nav-link-icon"><i className="fa fa-image"></i></div>
                                        Banner Management
                                    </Link>
                                    : null
                                } */}
                            </div>
                        </div>
                    </nav >
                </div >
                <Outlet />
            </div>
        </>
    )
}

export default DashboardPage;