import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../../customComponent/CustomAlertMessage";
import moment, { duration } from "moment";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import DataTableBase from "../../../customComponent/DataTable";

const PhaseManagement = () => {
  const [exportData, setExportData] = useState([]);
  const [allData, setallData] = useState([]);
  const [phaseNumber, setphaseNumber] = useState("");
  const [durationDays, setDurationDays] = useState("");
  const [amount, setAmount] = useState('');
  const [phaseStatus, setPhaseStatus] = useState("");

  const [id, setId] = useState('');

  const handleInputChange = (event) => {
    switch (event.target.name) {
      case "phaseNumber":
        setphaseNumber(event.target.value);
        break;
      case "durationDays":
        setDurationDays(event.target.value);
        break;
      case "amount":
        setAmount(event.target.value);
        break;
      case "packageStatus":
        setPhaseStatus(event.target.value);
        break;

      default:
    }
  }

  const AddNewPhase = async (phaseNumber, durationDays, amount) => {
    try {
      const result = await AuthService.createNewPhase(phaseNumber, durationDays, amount);
      if (result.success) {
        alertSuccessMessage(result.message);
        handleData();
      } else {
        alertErrorMessage(result.message);
      }
    } catch (error) {
      alertErrorMessage(error);
    } finally {
      LoaderHelper.loaderStatus(false);
    }
  };

  useEffect(() => {
    handleData();
  }, []);

  const handleData = async () => {
    LoaderHelper.loaderStatus(true);
    try {
      const result = await AuthService.getPhaseList();
      if (result.success) {
        setExportData(result?.data?.reverse());
        setallData(result?.data);
        if (result?.data?.length > 0) {
          setId(result?.data[0]._id);
        }
      } else {
        alertErrorMessage(result?.message);
      }
    } catch (error) {
    } finally {
      LoaderHelper.loaderStatus(false);
    }
  };


  const linkFollow = (row) => (
    <div>
      <button className="btn btn-success btn-sm me-2" data-bs-toggle="modal" data-bs-target="#view_modal">
        <i class="fas fa-eye"></i>
      </button>
      <button className="btn btn-primary btn-sm me-2" data-bs-toggle="modal" data-bs-target="#edit_modal" onClick={() => handleEditModalOpen(row)}>
        <i class="fas fa-edit"></i>
      </button>
      <button className="btn btn-danger btn-sm" type="button" onClick={() => handleDelete(row?._id)}><i class="fas fa-trash-alt"></i></button>
    </div>
  );

  const linkFollow1 = (row) => (
    <div>
      {row?.phase_status === "ACTIVE" ? (
        <button className="btn btn-success btn-sm me-2" onClick={() => handleStatus(row?._id, "INACTIVE")}>
          Active
        </button>
      ) : (
        <button className="btn btn-danger btn-sm me-2" onClick={() => handleStatus(row?._id, "ACTIVE")}>
          Inactive
        </button>
      )}
    </div>
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const indexOfLastData = currentPage * rowsPerPage;
    const indexOfFirstData = indexOfLastData - rowsPerPage;
    setExportData(allData.slice(indexOfFirstData, indexOfLastData));
  }, [currentPage, rowsPerPage, allData]);

  const calculateSRNo = (index) => {
    return index + 1 + (currentPage - 1) * rowsPerPage;
  };
  const columns = [
    { name: "SR No", selector: (row, index) => calculateSRNo(index), grow: 0.2, wrap: true, center: true },
    { name: "Phase Number", selector: (row) => row?.phase_number, sort: true, wrap: true, },
    // { name: "Burn Rate ", selector: (row) => row?.burn_rate, sort: true, wrap: true, },
    // { name: "Amount", selector: (row) => row?.amount, sort: true, wrap: true, },
    { name: "Duration Days ", selector: (row) => row?.duration_days, sort: true, wrap: true, },
    { name: "Date", selector: (row) => moment(row?.createdAt).format("Do MMMM YYYY"), sort: true, wrap: true, },
    { name: "Phase Status", selector: linkFollow1 },
    { name: "Action", cell: linkFollow, wrap: true },
  ];

  function searchObjects(e) {
    const keysToSearch = ["phase_number", "amount", "duration_days"];
    const userInput = e.target.value;
    const searchTerm = userInput?.toLowerCase();
    const matchingObjects = allData?.filter((obj) =>
      keysToSearch.some((key) => obj[key]?.toString()?.toLowerCase()?.includes(searchTerm))
    );
    setExportData(matchingObjects);
  }


  const handleStatus = async (id, status) => {
    LoaderHelper.loaderStatus(true);
    try {
      const result = await AuthService.PhaseStatus(id, status);
      if (result?.success) {
        LoaderHelper.loaderStatus(false);
        handleData();
        alertSuccessMessage(result?.message);
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result?.message);
      }
    } catch (error) {
      LoaderHelper.loaderStatus(false);
      alertErrorMessage(error);
    } finally {
      LoaderHelper.loaderStatus(false);
    }
  };
  const handleEditPhase = async (phaseNumber, durationDays, amount, id) => {
    LoaderHelper.loaderStatus(true);
    try {
      const result = await AuthService.editPhase(phaseNumber, durationDays, amount, id);
      if (result?.success) {
        LoaderHelper.loaderStatus(false);
        alertSuccessMessage(result?.message);
        handleData();
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    } catch (error) {
      alertErrorMessage(error);
    } finally {
      LoaderHelper.loaderStatus(false);
    }
  };

  const handleEditModalOpen = (row) => {
    setId(row?._id);
    setphaseNumber(row?.phase_number);
    setDurationDays(row?.duration_days);

  };


  const handleDelete = async (id) => {
    LoaderHelper.loaderStatus(true);
    try {
      const result = await AuthService.deletePhase(id);
      if (result?.success) {
        LoaderHelper.loaderStatus(false);
        alertSuccessMessage(result?.message);
        handleData();
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result?.message);
      }
    } catch (error) {
      alertErrorMessage(error);
    } finally {
      LoaderHelper.loaderStatus(false);
    }
  };


  return (
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
          <div className="container-xl px-4">
            <div className="page-header-content pt-4">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto mt-4">
                  <h1 className="page-header-title">
                    <div className="page-header-icon">
                      <i className="fa fa-wave-square"></i>
                    </div>
                    Phase Management List
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="container-xl px-4 mt-n10">
          <div className="card mb-4">
            <div className="card-header d-flex justify-content-between">
              Phase Details
              <div className="col-5">
                <input className="form-control form-control-solid" id="inputLastName" type="text" placeholder="Search here..." name="search" onChange={searchObjects} />
              </div>
              <div className="dropdown">
                <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#add_modal">
                  <i className="fas fa-plus me-2"></i> Create Phase
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive" width="100%">
                <DataTableBase
                  columns={columns}
                  data={exportData}
                  pagination
                  paginationServer
                  paginationTotalRows={allData.length}
                  onChangeRowsPerPage={setRowsPerPage}
                  onChangePage={setCurrentPage}
                  paginationPerPage={rowsPerPage}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* Add Modal */}
      <div className="modal fade" id="add_modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1```jsx aria-labelledby=" aria-hidden="true" >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="add_modalLabel">
                Add New Phase
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="row g-4 gx-md-5">
                <div className="col-md-6">
                  <label>
                    Phase Number <span className="text-danger">* </span>
                  </label>
                  <input type="text" onWheel={(e) => e.target.blur()} className="form-control form-control-solid" name="phaseNumber" placeholder="Enter packege Name" value={phaseNumber}
                    onChange={handleInputChange} required />
                </div>
                <div className="col-md-6">
                  <label>
                    Duration Days <span className="text-danger">*</span>
                  </label>
                  <input type="text" onWheel={(e) => e.target.blur()} className="form-control form-control-solid" name="durationDays" placeholder="Enter packege price" value={durationDays}
                    onChange={handleInputChange} />
                </div>
                {/* <div className="col-md-6">
                  <label>
                    Amount <span className="text-danger">*</span>
                  </label>
                  <input type="text" onWheel={(e) => e.target.blur()} className="form-control form-control-solid" name="amount" placeholder="Enter packege price" value={amount}
                    onChange={handleInputChange} />
                </div> */}
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-danger px-5" data-bs-dismiss="modal">
                Cancel
              </button>
              <button type="button" className="btn btn-success px-5" data-bs-dismiss="modal"
                onClick={() => AddNewPhase(phaseNumber, durationDays, amount)}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Edit Modal */}
      <div className="modal fade" id="edit_modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="edit_modalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="edit_modalLabel">
                Edit Phase Details
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="row g-4 gx-md-5">
                <div className="col-md-6">
                  <label>
                    Phase Number<span className="text-danger">* </span>
                  </label>
                  <input type="text" onWheel={(e) => e.target.blur()} className="form-control form-control-solid" name="phaseNumber" placeholder="Enter Package Name" value={phaseNumber}
                    onChange={handleInputChange} required />
                </div>
                <div className="col-md-6">
                  <label>
                    Duration Days <span className="text-danger">* </span>
                  </label>
                  <input type="text" onWheel={(e) => e.target.blur()} className="form-control form-control-solid" name="durationDays" placeholder="Enter Package Price" value={durationDays}
                    onChange={handleInputChange} required />
                </div>
                <div className="col-md-6">
                  <label>
                    Amount <span className="text-danger">* </span>
                  </label>
                  <input type="text" onWheel={(e) => e.target.blur()} className="form-control form-control-solid" name="amount" placeholder="Enter Package Price" value={amount}
                    onChange={handleInputChange} required />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-dark px-5" data-bs-dismiss="modal">
                Cancel
              </button>
              <button type="button" className="btn btn-primary px-5" data-bs-dismiss="modal"
                onClick={() => handleEditPhase(phaseNumber, durationDays, amount, id)}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* View Modal */}
      <div className="modal fade" id="view_modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="edit_modalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="edit_modalLabel">
                View Phase Details
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="row g-4 gx-md-5">
                <div className="col-md-6">
                  <label>
                    Phase Number<span className="text-danger">* </span>
                  </label>
                  <input type="text" onWheel={(e) => e.target.blur()} className="form-control form-control-solid" name="phaseNumber" placeholder="Enter Package Name" value={phaseNumber}
                    onChange={handleInputChange} required />
                </div>
                <div className="col-md-6">
                  <label>
                    Duration Days <span className="text-danger">* </span>
                  </label>
                  <input type="text" onWheel={(e) => e.target.blur()} className="form-control form-control-solid" name="durationDays" placeholder="Enter Package Price" value={durationDays}
                    onChange={handleInputChange} required />
                </div>
                <div className="col-md-6">
                  <label>
                    Amount <span className="text-danger">* </span>
                  </label>
                  <input type="text" onWheel={(e) => e.target.blur()} className="form-control form-control-solid" name="amount" placeholder="Enter Package Price" value={amount}
                    onChange={handleInputChange} required />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-danger px-5" data-bs-dismiss="modal">
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default PhaseManagement;
