import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage } from "../../../customComponent/CustomAlertMessage";
import moment from "moment";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import DataTableBase from "../../../customComponent/DataTable";
import * as xlsx from 'xlsx';
import { saveAs } from 'file-saver';

const RejectedKyc = () => {
    const [data, setData] = useState([]);
    const [allData, setAllData] = useState([]);
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');

    const handleInputChange = (event) => {
        switch (event.target.name) {
            case "dateFrom":
                setDateFrom(event.target.value);
                break;
            case "dateTo":
                setDateTo(event.target.value);
                break;
            default:
        }
    }


    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        const indexOfLastData = currentPage * rowsPerPage;
        const indexOfFirstData = indexOfLastData - rowsPerPage;
        setData(allData.slice(indexOfFirstData, indexOfLastData));
    }, [currentPage, rowsPerPage, allData]);

    const calculateSRNo = (index) => {
        return index + 1 + (currentPage - 1) * rowsPerPage;
    };
    const columns = [
        { name: "SR No", selector: (row, index) => calculateSRNo(index), wrap: true, center: true },
        { name: "Name", selector: row => row?.fullName, },
        { name: "Email Id", wrap: true, selector: row => row?.email, },
        { name: "Date & Time", selector: row => moment(row?.createdAt).format("Do MMMM YYYY"), },
    ];
    const downloadExcel = (data) => {
        const formattedData = data?.map((row, index) => ({
            "Sr. No.": calculateSRNo(index),
            "User Name": row?.fullName,
            "Email Id": row?.email,
            "Date & Time": moment(row?.createdAt).format("Do MMMM YYYY hh:mm A"),
        }));
        const worksheet = xlsx.utils.json_to_sheet(formattedData);
        console.log(formattedData, "formattedData");
        const workbook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(workbook, worksheet, "User Data");
        const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, 'RejectedKyc_data.xlsx');
    };

    useEffect(() => {
        handleData()
    }, []);

    const handleData = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.getdatarejectedlist().then(async result => {
            if (result.success) {
                try {
                    LoaderHelper.loaderStatus(false);
                    setData(result.data.reverse());
                    setAllData(result.data.reverse());
                    setDateFrom("");
                    setDateTo("");
                } catch (error) {
                    LoaderHelper.loaderStatus(false);
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });
    }

    const handleExportData = async (dateFrom, dateTo) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.exportApprovedList(dateFrom, dateTo).then(async result => {
            if (result.data.length > 0) {
                try {
                    LoaderHelper.loaderStatus(false);
                    setData(result.data.reverse());
                } catch (error) {
                    LoaderHelper.loaderStatus(false);
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage("No Data Found");
            }
        });
    }

    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto mt-4">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon"> <i className="fa fa-user-check" ></i></div>
                                        Rejected Kyc
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-xl px-4 mt-n10">
                    {data.length === 0 ? (""
                    ) : (
                        <div className="filter_bar" >
                            <form className="row" >
                                <div className="mb-3 col ">
                                    <input type="date" className="form-control form-control-solid" data-provide="datepicker" id="litepickerRangePlugin" name="dateFrom" value={dateFrom} onChange={handleInputChange} />
                                </div>
                                <div className="mb-3 col ">
                                    <input type="date" className="form-control form-control-solid" data-provide="datepicker" id="litepickerRangePlugin" name="dateTo" value={dateTo} onChange={handleInputChange} />
                                </div>
                                <div className="mb-3 col ">
                                    <div className="row" >
                                        <div className="col" >
                                            <button className="btn btn-indigo btn-block w-100" type="button" onClick={() => handleExportData(dateFrom, dateTo)} disabled={!dateFrom || !dateTo}>Search</button>
                                        </div>
                                        <div className="col" >
                                            <button className="btn btn-indigo btn-block w-100" type="button" onClick={handleData}>Reset</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>)}
                    <div className="card mb-4">
                        <div className="card-header">Rejected Kyc List

                            {data.length === 0 ? (
                                ""
                            ) : (
                                <div className="dropdown">
                                    <button className="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fa fa-download me-3"></i>Export</button>
                                    <div className="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                                        {/* <CSVLink data={data} className="dropdown-item">Export as CSV</CSVLink> */}
                                        <button className="dropdown-item" onClick={() => downloadExcel(data)}>
                                            Export as Excel</button>
                                    </div>
                                </div>)}
                        </div>
                        <div className="card-body">
                            <div className="table-responsive" width="100%">
                                <DataTableBase
                                    columns={columns}
                                    data={data}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={allData.length}
                                    onChangeRowsPerPage={setRowsPerPage}
                                    onChangePage={setCurrentPage}
                                    paginationPerPage={rowsPerPage}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default RejectedKyc;