import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Loginpage from "../ui/pages/LoginPage";
import ForgetpasswordPage from "../ui/pages/ForgetpasswordPage";
import DashboardPage from "../ui/pages/DashboardPage";
import HomePage from "../ui/pages/HomePage";
import ApprovedKyc from "../ui/pages/ApprovedKyc";
import PendingKyc from "../ui/pages/PendingKyc";
import RejectedKyc from "../ui/pages/RejectedKyc";
import { ToastContainer } from "react-toastify";
import UserList from "../ui/pages/UserList";
import CoinList from "../ui/pages/CoinList";
import HelpandSupport from "../ui/pages/HelpandSupport";
import ReferralList from "../ui/pages/ReferralList";
import PhaseManagement from "../ui/pages/PhaseManagement";
import PackageManagement from "../ui/pages/PackageManagement";
import MasterPassword from "../ui/pages/Settings/MasterPassword";

const Routing = () => {
    const token = sessionStorage.getItem('token');

    return (
        <Router>
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss={false} draggable pauseOnHover limit={1} theme="light" />
            <Routes>
                {token ?
                    <>
                        <Route exact path="/dashboard" element={<DashboardPage />} >
                            <Route index path="*" element={<HomePage />}></Route>
                            <Route exect path="homepage" element={<HomePage />}></Route>
                            <Route exect path="UserList" element={<UserList />}></Route>
                            <Route exect path="coinList" element={<CoinList />}></Route>
                            <Route exect path="pendingkyc" element={<PendingKyc />}></Route>
                            <Route exect path="approvedkyc" element={<ApprovedKyc />}></Route>
                            <Route exect path="RejectedKyc" element={<RejectedKyc />}></Route>
                            <Route exect path="HelpandSupport" element={<HelpandSupport />}></Route>
                            <Route exect path="ReferralList" element={<ReferralList />}></Route>
                            <Route exect path="phaseManagement" element={<PhaseManagement />}></Route>
                            <Route exect path="packageManagement" element={<PackageManagement />}></Route>
                            <Route exect path="MasterPassword" element={<MasterPassword />}></Route>


                        </Route>
                        <Route exect path="/*" element={<DashboardPage />}></Route>
                    </>
                    :
                    <>
                        <Route exect path="/" element={<Loginpage />}></Route>
                        <Route exect path="/*" element={<Loginpage />}></Route>
                        <Route exect path="/forgotpassword" element={<ForgetpasswordPage />}></Route>
                    </>

                }
            </Routes>
        </Router>
    );
}

export default Routing;