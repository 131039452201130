import React, { useEffect, useState } from 'react';
import { alertErrorMessage, alertSuccessMessage } from '../../../customComponent/CustomAlertMessage';
import AuthService from '../../../api/services/AuthService';
import DataTableBase from '../../../customComponent/DataTable';
import moment from 'moment';
import LoaderHelper from '../../../customComponent/Loading/LoaderHelper';
import * as xlsx from 'xlsx';
import { saveAs } from 'file-saver';

const UserList = () => {
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [userId, setUserId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");


  useEffect(() => {
    handleUserData();
  }, []);



  const handleUserData = async () => {
    LoaderHelper.loaderStatus(true);
    try {
      const result = await AuthService.getUserListData();
      if (result.success) {
        // alertSuccessMessage(result.message);
        setAllData(result?.data?.reverse());
      } else {
        alertErrorMessage(result.message);
      }
    } catch (error) {
      alertErrorMessage("An error occurred while fetching the user data.");
    } finally {
      LoaderHelper.loaderStatus(false);
    }
  };

  const handleInputChange = (event) => {
    switch (event.target.name) {
      case "firstName":
        setFirstName(event.target.value);
        break;
      case "lastName":
        setLastName(event.target.value);
        break;
      case "mobileNumber":
        setMobileNumber(event.target.value);
        break;
      case "email":
        setEmail(event.target.value);
        break;
      default:
    }
  };

  const handleStatus = async (id, status) => {
    try {
      const result = await AuthService.handleUserStatus(id, status);
      if (result.success) {
        alertSuccessMessage(result.message);
        handleUserData();
      } else {
        alertErrorMessage(result.message);
      }
    } catch (error) {
      alertErrorMessage("An error occurred while updating the user status.");
    }
  };

  const linkFollow = (row) => {
    return (
      <>
        {row?.status === "ACTIVE" ? (
          <button
            className="btn btn-success btn-sm me-2"
            onClick={() => handleStatus(row?._id, "INACTIVE")}
          >
            Active
          </button>
        ) : (
          <button
            className="btn btn-danger btn-sm me-2"
            onClick={() => handleStatus(row?._id, "ACTIVE")}
          >
            Inactive
          </button>
        )}
        <button
          className="btn btn-primary btn-sm me-2"
          data-bs-toggle="modal"
          data-bs-target="#edit_modal"
          onClick={() => handleEditModalOpen(row)}
        >
          <i className="fas fa-edit"></i>
        </button>
      </>
    );
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const indexOfLastData = currentPage * rowsPerPage;
    const indexOfFirstData = indexOfLastData - rowsPerPage;
    setData(allData.slice(indexOfFirstData, indexOfLastData));
  }, [currentPage, rowsPerPage, allData]);

  const calculateSRNo = (index) => {
    return index + 1 + (currentPage - 1) * rowsPerPage;
  };
  const columns = [
    { name: "Sr. No.", selector: (row, index) => calculateSRNo(index), grow: 0.2, wrap: true, center: true },
    { name: "User Name", selector: row => row?.fullName, grow: 0.5, wrap: true },
    { name: "Email Id", selector: row => row?.email, wrap: true },
    { name: "Mobile Number", selector: row => row?.mobile_number, grow: 0.5, wrap: true },
    { name: "Active Date", selector: row => moment(row?.createdAt).format("Do MMMM YYYY hh:mm A"), wrap: true, grow: 0.5 },
    { name: "Status", selector: linkFollow, grow: 0.5 },
  ];

  const searchObjects = (e) => {
    const keysToSearch = ["fullName", "email", "createdAt", "status", "mobile_number"];
    const searchTerm = e.target.value?.toLowerCase();
    const matchingObjects = allData.filter(obj =>
      keysToSearch.some(key => obj[key]?.toString()?.toLowerCase()?.includes(searchTerm))
    );
    setData(matchingObjects);
    setCurrentPage(1);
  };

  const downloadExcel = (data) => {
    const formattedData = data.map((row, index) => ({
      "Sr. No.": calculateSRNo(index),
      "User Name": row?.fullName,
      "Email Id": row?.email,
      "Mobile Number": row?.mobile_number,
      "Active Date": moment(row?.createdAt).format("Do MMMM YYYY hh:mm A"),
    }));
    const worksheet = xlsx.utils.json_to_sheet(formattedData);
    const workbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(workbook, worksheet, "User Data");

    const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, 'user_data.xlsx');
  };

  const handleEditModalOpen = (row) => {
    setUserId(row?._id);
    setFirstName(row?.firstName);
    setLastName(row?.lastName);
    setEmail(row?.email);
    setMobileNumber(row?.mobile_number);
  };

  const handleProfileEdit = async (userId, firstName, lastName, email, mobileNumber) => {
    LoaderHelper.loaderStatus(true);
    try {
      const result = await AuthService.editprofileData(userId, firstName, lastName, email, mobileNumber);
      if (result?.success) {
        alertSuccessMessage(result?.message);
        handleUserData();
      } else {
        alertErrorMessage(result.message);
      }
    } catch (error) {
      alertErrorMessage("An error occurred while editing the profile.");
    } finally {
      LoaderHelper.loaderStatus(false);
    }
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
          <div className="container-xl px-4">
            <div className="page-header-content pt-4">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto mt-4">
                  <h1 className="page-header-title">
                    <div className="page-header-icon">
                      <i className="fa fa-check-circle"></i>
                    </div>
                    User's Details
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="container-xl px-4 mt-n10">
          <div className="card mb-4">
            <div className="card-header d-flex justify-content-between">
              User Verification Details
              <div className="col-5">
                <input
                  className="form-control form-control-solid"
                  id="inputLastName"
                  type="text"
                  placeholder="Search here..."
                  name="search"
                  onChange={searchObjects}
                />
              </div>
              <div className="dropdown">
                <button
                  className="btn btn-dark btn-sm dropdown-toggle"
                  id="dropdownFadeInUp"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fa fa-download me-3"></i> Export
                </button>
                <div
                  className="dropdown-menu animated--fade-in-up"
                  aria-labelledby="dropdownFadeInUp"
                >

                  <button
                    className="dropdown-item"
                    onClick={() => downloadExcel(data)}
                  >
                    Export as Excel
                  </button>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive" width="100%">
                <DataTableBase
                  columns={columns}
                  data={data}
                  pagination
                  paginationServer
                  paginationTotalRows={allData.length}
                  onChangeRowsPerPage={setRowsPerPage}
                  onChangePage={setCurrentPage}
                  paginationPerPage={rowsPerPage}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Edit Modal */}
        <div
          className="modal fade"
          id="edit_modal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="edit_modalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="edit_modalLabel">
                  Edit User Details
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row g-4 gx-md-5">
                  <div className="col-md-6">
                    <label>
                      First Name <span className="text-danger">* </span>
                    </label>
                    <input
                      type="text"
                      onWheel={(e) => e.target.blur()}
                      className="form-control form-control-solid"
                      name="firstName"
                      placeholder="Enter First Name"
                      value={firstName}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <label>
                      Last Name <span className="text-danger">* </span>
                    </label>
                    <input
                      type="text"
                      onWheel={(e) => e.target.blur()}
                      className="form-control form-control-solid"
                      name="lastName"
                      placeholder="Enter Last Name"
                      value={lastName}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <label>
                      Email Id <span className="text-danger">* </span>
                    </label>
                    <input
                      type="text"
                      onWheel={(e) => e.target.blur()}
                      className="form-control form-control-solid"
                      name="email"
                      placeholder="Enter Email Id"
                      value={email}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <label>
                      Mobile Number <span className="text-danger">* </span>
                    </label>
                    <input
                      type="text"
                      onWheel={(e) => e.target.blur()}
                      className="form-control form-control-solid"
                      name="mobileNumber"
                      placeholder="Enter Mobile Number"
                      value={mobileNumber}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-dark px-5"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary px-5"
                  data-bs-dismiss="modal"
                  onClick={() =>
                    handleProfileEdit(userId, firstName, lastName, email, mobileNumber)
                  }
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default UserList;
