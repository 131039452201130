import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../../customComponent/CustomAlertMessage";
import moment from "moment";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import DataTableBase from "../../../customComponent/DataTable";
import { ApiConfig } from "../../../api/apiConfig/ApiConfig";

const CoinList = () => {
  const [exportData, setExportData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [coinName, setCoinName] = useState('');
  const [coinLogo, setCoinLogo] = useState(null);
  const [cShortName, setCShortName] = useState('');
  const [coinStatus, setCoinStatus] = useState('ACTIVE');
  const [showImage, setShowImage] = useState("");
  const [id, setId] = useState('');
  const [phases, setPhases] = useState([{ PhaseNumber: 1, price: '' }]);

  const handleInputChange = (event) => {
    switch (event.target.name) {
      case "coinName":
        setCoinName(event.target.value);
        break;
      case "shortName":
        setCShortName(event.target.value);
        break;
      case "coinStatus":
        setCoinStatus(event.target.value);
        break;
      default:
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setCoinLogo(file);
  };

  const handlePhaseChange = (index, event) => {
    const newPhases = [...phases];
    newPhases[index][event.target.name] = event.target.value;
    setPhases(newPhases);
  };

  const addPhase = () => {
    setPhases([...phases, { PhaseNumber: phases.length + 1, price: '' }]);
  };

  const handlePhaseDelete = (index) => {
    const newPhases = phases.filter((_, i) => i !== index);
    setPhases(newPhases);
  };

  const linkFollow = (row) => (
    <div>
      <button
        className="btn btn-primary btn-sm me-2"
        data-bs-toggle="modal"
        data-bs-target="#edit_modal"
        onClick={() => handleEditModalOpen(row)}
      >
        <i className="fas fa-edit"></i>
      </button>
      <button className="btn btn-danger btn-sm" type="button" onClick={() => handleDelete(row?._id)}><i className="fas fa-trash-alt"></i></button>
    </div>
  );

  const linkFollow1 = (row) => (
    <div>
      {row?.coin_status === "ACTIVE" ? (
        <button className="btn btn-success btn-sm me-2" onClick={() => handleStatus(row?._id, "INACTIVE")}>
          Active
        </button>
      ) : (
        <button className="btn btn-danger btn-sm me-2" onClick={() => handleStatus(row?._id, "ACTIVE")}>
          Inactive
        </button>
      )}
    </div>
  );

  const imageFormatter = (row) => {
    return (
      <button className="btn btn-sm" onClick={() => handleImageShow(ApiConfig?.appUrl + row?.coin_logo)} data-bs-toggle="modal" data-bs-target="#ImageModal">
        <i className="fas fa-eye"></i>
      </button>
    );
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const indexOfLastData = currentPage * rowsPerPage;
    const indexOfFirstData = indexOfLastData - rowsPerPage;
    setExportData(allData.slice(indexOfFirstData, indexOfLastData));
  }, [currentPage, rowsPerPage, allData]);

  const calculateSRNo = (index) => {
    return index + 1 + (currentPage - 1) * rowsPerPage;
  };
  const columns = [
    { name: "SR No", selector: (row, index) => calculateSRNo(index), grow: 0.2, wrap: true, center: true },
    { name: "Coin Name", selector: (row) => row?.coin_name, sort: true, wrap: true },
    { name: "Coin Short Name", selector: (row) => row?.coin_short_name, sort: true, wrap: true },
    { name: "Phase Number", selector: (row) => row?.coin_price_with_phase?.map(phase => phase.PhaseNumber).join(', '), sort: true, wrap: true },
    { name: "Coin Price", selector: (row) => row?.coin_price_with_phase?.map(phase => phase.price).join(', '), sort: true, wrap: true },
    { name: "Date", selector: (row) => moment(row?.createdAt).format("Do MMMM YYYY hh:mm A"), sort: true, wrap: true },
    { name: "Coin Logo", selector: imageFormatter },
    { name: "Coin Status", selector: linkFollow1 },
    { name: "Action", cell: linkFollow, wrap: true },
  ];


  function searchObjects(e) {
    const keysToSearch = ["_id", "coin_name", "coin_short_name", "coin_status"];
    const userInput = e.target.value;
    const searchTerm = userInput?.toLowerCase();
    const matchingObjects = allData?.filter((obj) =>
      keysToSearch.some((key) => obj[key]?.toString()?.toLowerCase()?.includes(searchTerm))
    );
    setExportData(matchingObjects);
    setCurrentPage(1);

  }

  const handleImageShow = (Img) => {
    setShowImage(Img);
  };

  const handleEditCoin = async (coinName, cShortName, id, coinLogo) => {
    const formData = new FormData();
    LoaderHelper.loaderStatus(true);
    formData.append("coin_name", coinName);
    formData.append("coin_short_name", cShortName);
    formData.append("coin_id", id);
    if (coinLogo) formData.append("coin_logo", coinLogo);
    formData.append("coin_price_with_phase", JSON.stringify(phases));
    try {
      const result = await AuthService.editCoinStatus(formData);
      if (result?.success) {
        alertSuccessMessage(result?.message);
        handleData();
      } else {
        alertErrorMessage(result.message);
      }
    } catch (error) {
      alertErrorMessage(error);
    } finally {
      LoaderHelper.loaderStatus(false);
    }
  };


  const handleDelete = async (id) => {
    LoaderHelper.loaderStatus(true);
    try {
      const result = await AuthService.deleteCoin(id);
      if (result?.success) {
        alertSuccessMessage(result?.message);
        handleData();
      } else {
        alertErrorMessage(result?.message);
      }
    } catch (error) {
      alertErrorMessage(error);
    } finally {
      LoaderHelper.loaderStatus(false);
    }
  };

  const handleStatus = async (id, status) => {
    LoaderHelper.loaderStatus(true);
    try {
      const result = await AuthService.coinStatus(id, status);
      if (result?.success) {
        handleData();
        alertSuccessMessage(result?.message);
      } else {
        alertErrorMessage(result?.message);
      }
    } catch (error) {
      alertErrorMessage(error);
    } finally {
      LoaderHelper.loaderStatus(false);
    }
  };

  const AddNewCoin = async (coinName, cShortName, coinStatus, phases, coinLogo) => {
    const formData = new FormData();
    LoaderHelper.loaderStatus(true);
    formData.append("coin_name", coinName);
    formData.append("coin_short_name", cShortName);
    formData.append("coin_status", coinStatus);
    formData.append("coin_price_with_phase", JSON.stringify(phases));
    if (coinLogo) formData.append("coin_logo", coinLogo);
    try {
      const result = await AuthService.AddNewCoin(formData);
      if (result.success) {
        alertSuccessMessage(result.message);
        handleData();
      } else {
        alertErrorMessage(result.message);
      }
    } catch (error) {
      alertErrorMessage(error?.message);
    } finally {
      LoaderHelper.loaderStatus(false);
    }
  };


  useEffect(() => {
    handleData();
  }, []);

  const handleData = async () => {
    LoaderHelper.loaderStatus(true);
    try {
      const result = await AuthService.getCoinList();
      if (result.success) {
        setExportData(result?.data.reverse());
        setAllData(result?.data);
        if (result?.data?.length > 0) {
          setId(result?.data[0]._id);
        }
      } else {
        alertErrorMessage(result?.message);
      }
    } catch (error) {
    } finally {
      LoaderHelper.loaderStatus(false);
    }
  };
  const handleEditModalOpen = (row) => {
    setId(row._id);
    setCoinName(row.coin_name);
    setCShortName(row.coin_short_name);
    setCoinLogo(null);
    try {
      setPhases(JSON.parse(row.coin_price_with_phase) || [{ PhaseNumber: 1, price: '' }]);
    } catch (e) {
      setPhases(row.coin_price_with_phase || [{ PhaseNumber: 1, price: '' }]);
    }
  };


  return (
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
          <div className="container-xl px-4">
            <div className="page-header-content pt-4">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto mt-4">
                  <h1 className="page-header-title">
                    <div className="page-header-icon">
                      <i className="fa fa-wave-square"></i>
                    </div>
                    Coin List
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="container-xl px-4 mt-n10">
          <div className="card mb-4">
            <div className="card-header d-flex justify-content-between">
              Coin's Details
              <div className="col-5">
                <input className="form-control form-control-solid" id="inputLastName" type="text" placeholder="Search here..." name="search" onChange={searchObjects} />
              </div>
              <div className="col-1">
                <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#add_modal">Add New</button>
              </div>
            </div>
            <div className="card-body">
              <DataTableBase
                columns={columns}
                data={exportData}
                pagination
                paginationServer
                paginationTotalRows={allData.length}
                onChangeRowsPerPage={setRowsPerPage}
                onChangePage={setCurrentPage}
                paginationPerPage={rowsPerPage}
              />
            </div>
          </div>
        </div>

        {/* Add New Coin Modal */}
        <div className="modal fade" id="add_modal" tabIndex="-1" aria-labelledby="add_modalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="add_modalLabel">Add New Coin</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="mb-3">
                    <label htmlFor="coinName" className="form-label">Coin Name</label>
                    <input type="text" className="form-control" id="coinName" name="coinName" value={coinName} onChange={handleInputChange} />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="shortName" className="form-label">Coin Short Name</label>
                    <input type="text" className="form-control" id="shortName" name="shortName" value={cShortName} onChange={handleInputChange} />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="coinLogo" className="form-label">Coin Logo</label>
                    <input type="file" className="form-control" id="coinLogo" onChange={handleFileChange} />
                  </div>
                  {phases.map((phase, index) => (
                    <div className="ph_card" key={index}>
                      <div className="row g-4 gx-md-5 align-items-center">
                        <div className="col-md-6">
                          <div className="no_phase">
                            <h5>Phase {phase.PhaseNumber}</h5>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <input
                            className="form-control form-control-solid"
                            name="price"
                            type="text"
                            placeholder="Enter Price"
                            value={phase.price}
                            onChange={(e) => handlePhaseChange(index, e)}
                          />
                          <button
                            type="button"
                            className="btn btn-danger btn-sm mt-2"
                            onClick={() => handlePhaseDelete(index)}
                          >
                            Delete Phase
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                  <button type="button" className="btn btn-primary" onClick={addPhase}>Add Phase</button>
                </form>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary" onClick={() => AddNewCoin(coinName, cShortName, coinStatus, phases, coinLogo)}>Save Coin</button>
              </div>
            </div>
          </div>
        </div>

        {/* Edit Coin Modal */}
        <div className="modal fade" id="edit_modal" tabIndex="-1" aria-labelledby="edit_modalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="edit_modalLabel">Edit Coin</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="mb-3">
                    <label htmlFor="coinName" className="form-label">Coin Name</label>
                    <input type="text" className="form-control" id="coinName" name="coinName" value={coinName} onChange={handleInputChange} />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="shortName" className="form-label">Coin Short Name</label>
                    <input type="text" className="form-control" id="shortName" name="shortName" value={cShortName} onChange={handleInputChange} />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="coinLogo" className="form-label">Coin Logo</label>
                    <input type="file" className="form-control" id="coinLogo" onChange={handleFileChange} />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary" onClick={() => handleEditCoin(coinName, cShortName, id, coinLogo)}>Save Changes</button>
              </div>
            </div>
          </div>
        </div>

        {/* Image Modal start */}
        <div className="modal fade" id="ImageModal" tabIndex="-1" aria-labelledby="ImageModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="ImageModalLabel">Coin Logo</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <img className="img-fluid" src={showImage} alt="Coin_Image" />
              </div>
            </div>
          </div>
        </div>
        {/* Image Modal end */}
      </main>
    </div>
  );
};

export default CoinList;
